@import "../../utils/colors";

.sbd-live-analytics-content {
    &__sensor-widgets {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
        gap: 20px;
        padding-bottom: 20px;
    }

    &__extra-widgets {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
        gap: 20px;
        padding-bottom: 20px;
    }

    &__table-view {
        padding-bottom: 20px;
        overflow: auto;

        /* &__results {
            background-color: $analytics-bg-color !important;
            position: sticky;
            top: 0;
            z-index: 4;
        } */
    }
}

.sbd-live-analytics-container__loader {
    z-index: 1 !important;
}
