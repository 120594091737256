@import "./dimensions";
@import "../../utils/colors";
@import "../../utils/mixins";

$animation-duration: 0.5s;
$content-margin-desktop-left: 20px;
$content-margin-desktop-right: 12px;
$content-margin-mobile: 12px;
$margin-right-mobile-expanded: 10px;

.sbd-dashboard-box-info {
  width: $dashboard-box-info-width;
  border-radius: 0 $dashboard-box-info-border-radius $dashboard-box-info-border-radius 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  @include responsive(phone) {
    width: calc(100vw - $margin-right-mobile-expanded);
  }
  @include responsive(desktop-down) {
    border-right: 1px solid #c4c4c4;
  }
  height: 100%;
  background-color: white;
  z-index: 4;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  transition: width $animation-duration, height $animation-duration, border-radius $animation-duration,
    left $animation-duration, top $animation-duration;

  &--collapsed {
    @include responsive(desktop-down) {
      width: $dashboard-box-info-width-mobile--collapsed;
      height: $dashboard-box-info-width-mobile--collapsed;
      border-radius: 100%;
      padding: 0;
      left: 10px;
      top: 10px;

      & .sbd-dashboard-box-info__scroll-container {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &--expanded {
    @include responsive(desktop-down) {
      box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
      border-radius: calc($dashboard-box-info-border-radius * 1.5);
      left: 0;
      top: 0;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 10px;
    @include responsive(desktop) {
      display: none;
    }

    &__expand {
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;
      z-index: 4;
    }

    &__collapse {
      z-index: 4;
    }
  }

  &__content {
    margin: 10px $content-margin-desktop-right 10px $content-margin-desktop-left;

    @include responsive(desktop-down) {
      margin: 10px $content-margin-mobile;
    }
  }

  &__image {
    @include responsive(desktop) {
      transition: opacity 0.5s;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    overflow: auto;

    opacity: 1;
    transition: opacity $animation-duration;

    min-width: $dashboard-box-info-width;
    @include responsive(phone) {
      min-width: calc(100vw - $margin-right-mobile-expanded - 1px);
      padding-bottom: 40px;
    }
  }
}
