.wbs-header {
  height: 48px;
  z-index: 9;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.wbs-header__nav {
  justify-content: left;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.wbs-header__nav__item {
  color: #525252;
  width: -webkit-min-content;
  width: min-content;
  font-weight: 450;
  text-decoration: none;
  transition: color .3s;
}

.wbs-header__nav__item--dropdown {
  width: auto;
}

.wbs-header__nav__item:hover {
  color: #000;
}

.wbs-header__nav__item--highlighted {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #c70244 0%, #7308ca 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 600;
}

.wbs-header__nav__item--selected {
  color: #000;
  font-weight: 600;
}

.wbs-header__nav__item--selected:before {
  content: "";
  height: 2px;
  width: calc(100% + 4px);
  background: #000;
  display: block;
  position: absolute;
  bottom: 0;
  transform: translateX(-2px);
}

.wbs-datenschutz-page {
  color: #fff;
  height: 120vh;
}

.wbs-footer {
  min-height: 90px;
  background-color: #f5f5f5;
  border-top: 1px solid #e3e3e3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 10px 20px;
  font-size: .85rem;
  display: flex;
}

.wbs-footer__copyright {
  font-size: .7rem;
}

.wbs-footer__nav {
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  display: flex;
}

@media (max-width: 767px) {
  .wbs-footer__nav {
    flex-direction: column;
    gap: .2rem;
  }
}

.wbs-footer__nav__item {
  color: #525252;
  font-weight: 450;
  text-decoration: none;
  transition: color .3s;
}

.wbs-footer__nav__item:hover {
  color: #000;
}

.wbs-footer__nav__item--selected {
  color: #000;
  font-weight: 600;
}

.sk-cube-grid {
  width: 100%;
  height: 100%;
  position: relative;
}

.sk-cube-grid__container {
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  float: left;
  background-color: #333;
  animation: sk-cubeGridScaleDelay 1.3s ease-in-out infinite;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: .2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: .3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: .4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: .1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: .2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: .3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: .1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: .2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3d(1, 1, 1);
  }

  35% {
    transform: scale3d(0, 0, 1);
  }
}

.spinner {
  width: 70px;
  text-align: center;
  margin: 100px auto 0;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  display: inline-block;
}

.spinner .bounce1 {
  animation-delay: -.32s;
}

.spinner .bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.sbd-dashboard-box-info {
  width: 320px;
  height: 100%;
  z-index: 4;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  transition: width .5s, height .5s, border-radius .5s, left .5s, top .5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 6px 24px #0000000d, 0 0 0 1px #00000014;
}

@media (max-width: 767px) {
  .sbd-dashboard-box-info {
    width: calc(100vw - 10px);
  }
}

@media (max-width: 1023px) {
  .sbd-dashboard-box-info {
    border-right: 1px solid #c4c4c4;
  }

  .sbd-dashboard-box-info--collapsed {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    padding: 0;
    top: 10px;
    left: 10px;
  }

  .sbd-dashboard-box-info--collapsed .sbd-dashboard-box-info__scroll-container {
    opacity: 0;
    pointer-events: none;
  }

  .sbd-dashboard-box-info--expanded {
    border-radius: 12px;
    top: 0;
    left: 0;
    box-shadow: 0 30px 90px #0006;
  }
}

.sbd-dashboard-box-info__icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 1024px) {
  .sbd-dashboard-box-info__icon {
    display: none;
  }
}

.sbd-dashboard-box-info__icon__expand {
  z-index: 4;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.sbd-dashboard-box-info__icon__collapse {
  z-index: 4;
}

.sbd-dashboard-box-info__content {
  margin: 10px 12px 10px 20px;
}

@media (max-width: 1023px) {
  .sbd-dashboard-box-info__content {
    margin: 10px 12px;
  }
}

@media (min-width: 1024px) {
  .sbd-dashboard-box-info__image {
    opacity: .5;
    transition: opacity .5s;
  }

  .sbd-dashboard-box-info__image:hover {
    opacity: 1;
  }
}

.sbd-dashboard-box-info__scroll-container {
  width: 100%;
  height: 100%;
  opacity: 1;
  min-width: 320px;
  transition: opacity .5s;
  overflow: auto;
}

@media (max-width: 767px) {
  .sbd-dashboard-box-info__scroll-container {
    min-width: calc(100vw - 11px);
    padding-bottom: 40px;
  }
}

@keyframes dtm-anim-popin {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: scaleX(.2);
  }

  15% {
    opacity: 1;
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes opacity-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sbd-search-grid {
  grid-template-columns: auto min-content;
  gap: 8px;
  display: grid;
}

.sbd-dashboard-header {
  height: 60px;
  z-index: 5;
  background-color: #fff;
  border-bottom: 1px solid #222;
  padding: 0 20px;
}

@media (max-width: 1023px) {
  .sbd-dashboard-header {
    padding: 0 8px;
  }
}

.sbd-dashboard-header__content {
  max-width: 2000px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.sbd-dashboard-header__bookmarks {
  height: 36px;
  width: -webkit-max-content;
  width: max-content;
  align-items: center;
  animation: scale-up-center 1s ease-in-out .2s;
  display: flex;
  position: relative;
}

@media (max-width: 1023px) {
  .sbd-dashboard-header__bookmarks {
    display: none;
  }
}

.sbd-dashboard-header__bookmarks__header {
  width: 30px;
  height: 30px;
  color: #757575;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: flex;
}

.sbd-dashboard-header__bookmarks__content {
  height: 100%;
  filter: blur();
  gap: 5px;
  transition: filter .6s;
  display: flex;
}

.sbd-dashboard-header__bookmarks__item {
  z-index: 5;
  border: 2px solid #fff;
  margin-top: auto;
  margin-right: -18px;
  transition: margin .35s;
}

.sbd-dashboard-header__bookmarks__item:hover {
  margin-right: 0;
  transform: scale(1.2);
}

.sbd-dashboard-header__bookmarks__item:not(:first-child):hover {
  margin-left: 15px;
}

.sbd-dashboard-header__bookmarks__item--active {
  border: 2px solid #40c05785;
  box-shadow: 0 0 2px #40c0578f;
}

.sbd-dashboard-header-search-result {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: .5rem;
  transition: background-color .2s;
}

.sbd-dashboard-header-search-result__info {
  gap: 0;
}

.sbd-dashboard-header-search-result:hover {
  background-color: #e0f2ff;
}

.sbd-no-data {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}



.sbd-live-analytics-content__sensor-widgets, .sbd-live-analytics-content__extra-widgets {
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  gap: 20px;
  padding-bottom: 20px;
  display: grid;
}

.sbd-live-analytics-content__table-view {
  padding-bottom: 20px;
  overflow: auto;
}

.sbd-live-analytics-container__loader {
  z-index: 1 !important;
}

.sbd-live-analytics-value-indicator {
  height: 12px;
  margin: 5px 0;
  position: relative;
}

.sbd-live-analytics-value-indicator__bar {
  height: 100%;
  width: 100%;
  border: 1px solid #2e2e2e;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sbd-live-analytics-value-indicator__indicator {
  height: calc(100% + 6px);
  width: 2px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.sbd-analytics-box-widget {
  overflow: initial;
}

.sbd-sun-api-widget {
  max-width: 600px;
}

@media (max-width: 1023px) {
  .sbd-sun-api-widget {
    max-width: 100%;
  }
}

.sbd-sun-api-widget__data-content {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.sbd-sun-api-widget__data-content--hidden {
  display: none;
}

@media (max-width: 1023px) {
  .sbd-sun-api-widget__data-content {
    border-top: 1px dashed #dadada;
    margin-top: 10px;
  }
}

.sbd-sun-api-widget__data-content__grid {
  max-width: 800px;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  margin: 10px auto;
  display: grid;
}

@media (max-width: 1023px) {
  .sbd-sun-api-widget__data-content__grid {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    justify-items: center;
    gap: 8px;
  }
}

@media (max-width: 424px) {
  .sbd-sun-api-widget__data-content__grid {
    grid-template-columns: 1fr !important;
  }
}

.sbd-sun-api-widget__data-content__graph {
  width: 300px;
  aspect-ratio: 1 / 1;
}

@media (max-width: 424px) {
  .sbd-sun-api-widget__data-content__graph {
    width: 100%;
  }
}

.sbd-sun-api-widget__data-content__graph canvas {
  width: 100%;
  height: 100%;
}

.sbd-sun-api-widget__data-content__info-field {
  flex-direction: column;
  display: flex;
}

@media (max-width: 1023px) {
  .sbd-sun-api-widget__data-content__info-field {
    align-items: center;
  }
}

.sbd-mantine-tabs-panel {
  height: calc(100% - 48px);
  overflow: auto;
  padding: 0 !important;
}

.sbd-mantine-tabs-root {
  height: 100%;
}

.sbd-dashboard-container {
  height: 100%;
  padding: 10px 20px;
}

@media (max-width: 1023px) {
  .sbd-dashboard-container {
    padding: 18px 20px;
  }
}

.sbd-dashboard-filters {
  margin-bottom: var(--mantine-spacing-sm);
  width: 100%;
  justify-content: space-between;
  gap: var(--mantine-spacing-sm);
  flex-direction: row;
  display: flex;
}

@media (max-width: 767px) {
  .sbd-dashboard-filters {
    flex-direction: column;
  }
}

.sbd-dashboard-not-supported {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  align-items: flex-start;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sbd-dashboard-main {
  width: 100vw;
  height: 100vh;
  background-color: #faebd7;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sbd-dashboard-content {
  height: calc(100vh - 100px);
  background-color: #b07fff;
  position: relative;
}

@media (max-width: 767px) {
  .sbd-dashboard-content {
    height: calc(100vh - 90px);
  }
}

.sbd-dashboard-content__analytics {
  height: 100%;
  opacity: 1;
  background-color: #f7f7f7;
  background-image: repeating-linear-gradient(45deg, #f1f1f1 0 .9px, #f7f7f7 0 50%);
  background-size: 9px 9px;
  padding-left: 320px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 1023px) {
  .sbd-dashboard-content__analytics {
    padding-left: 0;
  }
}

.sbd-dashboard-content__analytics__tab-header {
  width: calc(100% + 8px);
  z-index: 1;
  background: #fff;
  padding-right: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transform: translateX(-8px);
}

@media (max-width: 1023px) {
  .sbd-dashboard-content__analytics__tab-header {
    padding-right: 8px;
  }
}

.sbd-dashboard-footer {
  height: 40px;
  z-index: 5;
  background-color: #fff;
  border-top: 1px solid #222;
  padding: 0 20px;
}

@media (max-width: 767px) {
  .sbd-dashboard-footer {
    height: 30px;
    padding: 0 12px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .sbd-dashboard-footer {
    padding: 0 8px;
  }
}

.sbd-dashboard-footer__content {
  max-width: 2000px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  font-size: .75rem;
  display: flex;
}

@media (max-width: 767px) {
  .sbd-dashboard-footer__content__version {
    border-radius: var(--mantine-radius-sm);
    background-color: var(--mantine-color-gray-2);
    padding: 2px 4px;
    font-size: 10px;
  }

  .sbd-dashboard-footer__content__title {
    display: none;
  }

  .sbd-dashboard-footer__content__copyright {
    font-size: 12px;
  }
}

.sbd-detailed-data-relative-time-container__section {
  flex-direction: column;
  display: flex;
}

.sbd-home-page {
  background: linear-gradient(150deg, #4ca742 50%, #1cbd89 100%);
}

.sbd-dashboard-btn-container {
  margin-top: 40px;
}

@media (max-width: 1023px) {
  .sbd-dashboard-btn-container {
    width: 100vw;
    z-index: 99;
    position: fixed;
    bottom: 100px;
  }
}

.sbd-home-feature-container {
  text-shadow: 2px 2px #00000023;
  padding: 40px 0;
}

.sbd-home-feature-container__group {
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  display: grid;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .sbd-home-feature-container__group {
    gap: .5rem;
  }
}

@media (max-width: 767px) {
  .sbd-home-feature-container__group {
    grid-template-rows: min-content min-content min-content;
    grid-template-columns: 1fr;
    gap: .5rem;
  }
}

.sbd-home-page-preview-image img {
  margin-left: auto;
  padding: 5px;
  box-shadow: 0 10px 36px #00000029, 0 0 0 1px #0000000f;
  width: 500px !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .sbd-home-page-preview-image img {
    margin: 0 auto;
    width: 90% !important;
  }
}

@media (max-width: 767px) {
  .sbd-home-page-preview-image img {
    margin: 0;
    padding: 0;
    width: 100% !important;
  }
}

.sbd-home-page-copyright {
  color: gray;
  z-index: 1;
  padding: .5rem;
  font-size: .7rem;
  position: fixed;
  top: 0;
  right: 0;
}

.sbd-page-shape-divider__shape {
  aspect-ratio: 1200 / 120;
  width: 100%;
  background-image: url("waves.95701236.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.sbd-page-shape-divider__rest {
  height: 50%;
  background-color: #fff;
}

.sbd-home-info {
  align-content: stretch;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

@media (max-width: 1023px) {
  .sbd-home-info {
    flex-direction: column;
    align-items: center;
  }
}

.sbd-home-info__stack {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media (max-width: 1023px) {
  .sbd-home-info__stack {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.sbd-home-info__stack__data {
  border-radius: var(--mantine-radius-sm);
  flex-direction: row;
  gap: .5rem;
  display: flex;
}

@media (max-width: 1023px) {
  .sbd-home-info__stack__data {
    width: 100%;
    background-color: var(--mantine-color-gray-1);
    justify-content: space-between;
    padding: .5rem;
  }
}

@media (max-width: 424px) {
  .sbd-home-info__stack__data {
    width: auto;
    flex-direction: column;
    align-items: center;
  }
}

.wbs-datenschutz-page {
  color: #fff;
  height: 120vh;
}

.wbs-datenschutz-page {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: green;
  height: 120vh;
}

.wsb-app-container {
  width: 100vw;
  height: 100vh;
  background-color: #2dc980;
  overflow: hidden;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--mantine-color-dark-2) var(--mantine-color-gray-2);
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--mantine-color-gray-2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--mantine-color-dark-2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--mantine-color-dark-3);
}

body {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background: linear-gradient(90deg, #323332 0%, #181818 100%);
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.full-width {
  width: 100%;
}

@media (max-width: 767px) {
  .sbd-hide--phone {
    display: none;
  }
}

@media (max-width: 1023px) {
  .sbd-hide--phone-and-tablet {
    display: none;
  }
}

@media (min-width: 1024px) {
  .sbd-hide--desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .sbd-hide--tablet-and-desktop {
    display: none;
  }
}

.page__container {
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  overflow-y: auto;
}

.page__content {
  z-index: 8;
  max-width: 1280px;
  min-height: calc(100vh - 138px);
  color: #000;
  word-wrap: break-word;
  background-color: #fff;
  margin: 0 auto;
  padding: 10px 28px 50px;
}

.mantine-Title-root:not(:first-child) {
  margin-top: 1rem;
}

h1.mantine-Title-root {
  font-size: 1.5em;
}

h2.mantine-Title-root {
  font-size: 1.2em;
}

h3.mantine-Title-root {
  font-size: .9em;
  text-decoration: underline;
}

h4.mantine-Title-root {
  font-size: .8em;
  text-decoration: underline;
}

h5.mantine-Title-root {
  font-size: .7em;
  text-decoration: underline;
}

h6.mantine-Title-root {
  font-size: .6em;
  text-decoration: underline;
}

@font-face {
  font-family: opensensemap;
  src: url("opensensemap.52eb387d.eot");
  src: url("opensensemap.52eb387d.eot#iefix") format("embedded-opentype"), url("opensensemap.29b96761.ttf") format("truetype"), url("opensensemap.fe7a8522.woff") format("woff"), url("opensensemap.ab8684d7.svg#opensensemap") format("svg");
  font-weight: normal;
  font-style: normal;
}

.osem-icon {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: opensensemap !important;
}

.osem-radioactive:before {
  content: "";
}

.osem-particulate-matter:before {
  content: "";
}

.osem-moisture:before {
  content: "";
}

.osem-temperature-celsius:before {
  content: "";
}

.osem-temperature-fahrenheit:before {
  content: "";
}

.osem-drops:before {
  content: "";
}

.osem-thermometer:before {
  content: "";
}

.osem-windspeed:before {
  content: "";
}

.osem-sprinkles:before {
  content: "";
}

.osem-brightness:before {
  content: "";
}

.osem-barometer:before {
  content: "";
}

.osem-humidity:before {
  content: "";
}

.osem-not-available:before {
  content: "";
}

.osem-gauge:before {
  content: "";
}

.osem-umbrella:before {
  content: "";
}

.osem-clock:before {
  content: "";
}

.osem-shock:before {
  content: "";
}

.osem-fire:before {
  content: "";
}

.osem-check:before {
  content: "";
}

.osem-close:before, .osem-remove:before, .osem-times:before {
  content: "";
}

.osem-signal:before {
  content: "";
}

.osem-cog:before, .osem-gear:before {
  content: "";
}

.osem-trash-o:before {
  content: "";
}

.osem-download:before {
  content: "";
}

.osem-volume-up:before {
  content: "";
}

.osem-register:before {
  content: "";
}

.osem-map-marker:before {
  content: "";
}

.osem-plus-circle:before {
  content: "";
}

.osem-check-circle:before {
  content: "";
}

.osem-info-circle:before {
  content: "";
}

.osem-twitter-square:before {
  content: "";
}

.osem-facebook-square:before {
  content: "";
}

.osem-github:before {
  content: "";
}

.osem-globe:before {
  content: "";
}

.osem-filter:before {
  content: "";
}

.osem-cloud:before {
  content: "";
}

.osem-dashboard:before, .osem-tachometer:before {
  content: "";
}

.osem-spinner:before {
  content: "";
}

.osem-microphone:before {
  content: "";
}

.osem-wifi:before {
  content: "";
}

.osem-battery:before {
  content: "";
}

.osem-co2:before {
  content: "";
}

/*# sourceMappingURL=index.f25754a9.css.map */
