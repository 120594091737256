// osm icons
//@import url("https://github.com/sensebox/openSenseMap/blob/master/app/styles/osemicons.css");
//@import "../../utils/icons.scss";

.wsb-app-container {
  background-color: rgb(45, 201, 128);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
