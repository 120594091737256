@import "./utils/mixins";
@import "./components/header/dimensions";
@import "./components/footer/dimensions";

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
  position: relative;
}

/* CUSTOM SCROLLBAR START*/
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--mantine-color-dark-2) var(--mantine-color-gray-2);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--mantine-color-gray-2);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--mantine-color-dark-2);
  &:hover {
    background-color: var(--mantine-color-dark-3);
  }
  //border-radius: var(--mantine-radius-xs);
  //border: 1px double var(--mantine-color-gray-3);
}
/* CUSTOM SCROLLBAR END*/

body {
  background: rgb(50, 51, 50);
  background: linear-gradient(90deg, rgba(50, 51, 50, 1) 0%, rgba(24, 24, 24, 1) 100%);
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.full-width {
  width: 100%;
}

.sbd-hide {
  &--phone {
    @include responsive(phone) {
      display: none;
    }
  }

  &--phone-and-tablet {
    @include responsive(desktop-down) {
      display: none;
    }
  }

  &--desktop {
    @include responsive(desktop) {
      display: none;
    }
  }

  &--tablet-and-desktop {
    @include responsive(phone-up) {
      display: none;
    }
  }
}

.page {
  &__container {
    background-color: white;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
  }

  &__content {
    z-index: 8;
    max-width: 1280px;
    min-height: calc(100vh - $header-height - $footer-height);
    margin: 0 auto;
    padding: 10px 28px 50px 28px;
    background-color: white;
    color: black;
    word-wrap: break-word;
  }
}

.mantine-Title-root { 
  &:not(:first-child) {
    margin-top: 1rem;
  }
}

h1.mantine-Title-root {
  font-size: 1.5em;
}

h2.mantine-Title-root {
  font-size: 1.2em;
}

h3.mantine-Title-root {
  font-size: 0.9em;
  text-decoration: underline;
}

h4.mantine-Title-root {
  font-size: 0.8em;
  text-decoration: underline;
}

h5.mantine-Title-root {
  font-size: 0.7em;
  text-decoration: underline;
}

h6.mantine-Title-root {
  font-size: 0.6em;
  text-decoration: underline;
}
