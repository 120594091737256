@import "./dimensions";
@import "../../utils/mixins";

.wbs-footer {
  min-height: $footer-height;
  background-color: #f5f5f5;
  border-top: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  font-size: 0.85rem;
  gap: 0.5rem;

  &__copyright {
    font-size: 0.7rem;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    @include responsive(phone) {
      flex-direction: column;
      gap: 0.2rem;
    }
    align-items: center;

    &__item {
      color: rgb(82, 82, 82);
      font-weight: 450;
      transition: color 0.3s;
      text-decoration: none;

      &:hover {
        color: black;
      }

      &--selected {
        color: black;
        font-weight: 600;
      }
    }
  }
}
